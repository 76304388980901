
/**
 * MQ3 AQ3 Pdf view
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  name: "Mq4Aq42SWPdf"
})
export default class Mq4Aq42SWPdf extends Vue {}
